import React, { ComponentProps, forwardRef } from 'react';
import { StyledButton, IconWrapper } from './button.style';

export type ButtonProps = ComponentProps<'button'> & {
  href?: string;
  icon?: JSX.Element;
  textColor?: string;
  fullWidth?: boolean;
  isSmall?: boolean;
  isWhite?: boolean;
  hasUnderline?: boolean;
  isTransparent?: boolean;
  isExtraTransparent?: boolean;
};

const Button: React.FC<ButtonProps> = forwardRef(
  (
    {
      children,
      href,
      icon,
      textColor,
      fullWidth,
      isSmall,
      isWhite,
      hasUnderline,
      isTransparent,
      isExtraTransparent,
      ...rest
    },
    ref,
  ) => (
    <StyledButton
      {...rest}
      ref={ref}
      as={href && 'a'}
      href={href}
      $textColor={textColor}
      $fullWidth={fullWidth}
      $isSmall={isSmall}
      $isWhite={isWhite}
      $hasUnderline={hasUnderline}
      $isTransparent={isTransparent || isExtraTransparent}
      $isExtraTransparent={isExtraTransparent}
      $hasIcon={!!icon}
    >
      {icon && <IconWrapper>{icon}</IconWrapper>}
      {children}
    </StyledButton>
  ),
);

Button.displayName = 'Button';

export default Button;
