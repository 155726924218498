import { useContext, useMemo } from 'react';
import { useRouter } from 'next/router';
import { useSelector } from 'react-redux';
import { Selectors as appSelectors } from 'domains/app';
import { RETURN_URL_QUERY_PARAM } from 'config/constants';
import { setUrlParams } from './utils';
import { RouteContext } from './context';

export const useHistory = (): string[] => {
  return useContext(RouteContext).history;
};

export const useBackFunction = (fallbackPath: string): (() => void) => {
  const router = useRouter();
  const history = useHistory();
  return () => {
    if (history.length > 1) {
      router.back();
      return;
    }
    router.push(fallbackPath);
  };
};

export const useArgumentsLink = (link: string, addReturnUrl?: boolean): string => {
  const { referenceArguments } = useContext(RouteContext);
  const currentUrl = useSelector(appSelectors.getHost);

  return useMemo(() => {
    const queryParams: Record<string, string | string[]> = referenceArguments ?? {};
    if (addReturnUrl) {
      queryParams[RETURN_URL_QUERY_PARAM] = encodeURIComponent(currentUrl);
    }

    return setUrlParams(link, queryParams);
  }, [referenceArguments, currentUrl, addReturnUrl, link]);
};
