import React, { ComponentProps } from 'react';
import { Container, Row, Col } from './grid.style';

export type GridProps = ComponentProps<'div'> & {
  columns?: string;
  as?: string;
  fullWidth?: boolean;
};

export type ColumnProps = ComponentProps<'div'> & {
  offset?: string;
  size?: string;
  order?: number;
  as?: string;
};

const Grid: React.FC<GridProps> = ({ columns = 'mb:2 tb:3 tl:4 dt:5', as, fullWidth, children }) => (
  <Container $fullWidth={fullWidth}>
    <Row $columns={columns} as={as}>
      {children}
    </Row>
  </Container>
);

export const Column: React.FC<ColumnProps> = ({ offset, size, order, as, children }) => (
  <Col $offset={offset} $size={size} $order={order} as={as}>
    {children}
  </Col>
);

export default Grid;
