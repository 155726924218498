import { CombinedState, createAsyncThunk } from '@reduxjs/toolkit';
import { Actions as appActions } from 'domains/app';
import { Actions as audioTracksActions } from 'domains/audio-tracks';
import { Actions as meditationsActions, Selectors as meditationsSelectors } from 'domains/meditations';
import { Actions as musicActions, Selectors as musicSelectors } from 'domains/music';
import { Actions as sectionsActions } from 'domains/sections';
import { Selectors as userSelectors } from 'domains/user';
import { AppInfo } from 'models/app-info';
import {
  Collection,
  CollectionTypes,
  SpotlightCollection,
  SpotlightMeditationCollection,
  SpotlightMusicTrackCollection,
} from 'models/collection';
import { Meditation } from 'models/meditation';
import { MusicTrack } from 'models/music-track';
import { State as RootState } from 'models/redux-state';
import { Track } from 'models/track';
import Axios from 'utils/axios';
import { NAME } from './constants';
import * as Selectors from './selectors';

export const fetchAppInfo = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-app-info`,
  async (_, thunkAPI) => {
    const _appInfo = Selectors.getLocalizedAppInfo(thunkAPI.getState());

    if (Object.keys(_appInfo).length) {
      return;
    }

    const locale = userSelectors.getLocale(thunkAPI.getState());
    const timezone = userSelectors.getTimezone(thunkAPI.getState());
    const { data } = await Axios.get<{ info: AppInfo }>('/api/app/info', { params: { locale, timezone } });

    thunkAPI.dispatch(appActions.updateInfo(locale, data.info));
  },
);

export const fetchSpotlight = createAsyncThunk<void, boolean, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-spotlight`,
  async (includeRelatedContent, thunkAPI) => {
    const locale = userSelectors.getLocale(thunkAPI.getState());
    const timezone = userSelectors.getTimezone(thunkAPI.getState());

    if (timezone) {
      const { data } = await Axios.get<{ collections: SpotlightCollection[] }>('/api/app/spotlight', {
        params: { locale, timezone },
      });

      thunkAPI.dispatch(sectionsActions.updateSpotlight(locale, data.collections));
    }
  },
);

export const fetchMoment = createAsyncThunk<void, boolean, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-moment`,
  async (includeRelatedContent, thunkAPI) => {
    const locale = userSelectors.getLocale(thunkAPI.getState());
    const timezone = userSelectors.getTimezone(thunkAPI.getState());

    if (timezone) {
      const { data } = await Axios.get<{
        collections: Collection[];
        meditations: Meditation[];
        musicTracks: MusicTrack[];
        tracks: Track[];
      }>('/api/app/moment', {
        params: { locale, timezone, includeRelatedContent: includeRelatedContent || undefined },
      });

      thunkAPI.dispatch(sectionsActions.updateMoment(locale, data.collections));
      thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
      thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }
  },
);

export const fetchNew = createAsyncThunk<void, boolean, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-new`,
  async (includeRelatedContent, thunkAPI) => {
    const locale = userSelectors.getLocale(thunkAPI.getState());

    const { data } = await Axios.get<{
      collections: Collection[];
      meditations: Meditation[];
      musicTracks: MusicTrack[];
      tracks: Track[];
    }>('/api/app/new', {
      params: { locale, includeRelatedContent: includeRelatedContent || undefined },
    });

    thunkAPI.dispatch(sectionsActions.updateNew(locale, data.collections));
    thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
    thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
    thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
  },
);

export const fetchTrending = createAsyncThunk<void, boolean, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-trending`,
  async (includeRelatedContent, thunkAPI) => {
    const locale = userSelectors.getLocale(thunkAPI.getState());

    const { data } = await Axios.get<{
      collections: Collection[];
      meditations: Meditation[];
      musicTracks: MusicTrack[];
      tracks: Track[];
    }>('/api/app/trending', {
      params: { locale, includeRelatedContent: includeRelatedContent || undefined },
    });

    thunkAPI.dispatch(sectionsActions.updateTrending(locale, data.collections));
    thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
    thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
    thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
  },
);

export const fetchForYou = createAsyncThunk<void, undefined, { state: CombinedState<RootState> }>(
  `${NAME}/fetch-for-you`,
  async (_, thunkAPI) => {
    await Promise.all([
      thunkAPI.dispatch(fetchSpotlight(false)),
      thunkAPI.dispatch(fetchMoment(false)),
      thunkAPI.dispatch(fetchNew(false)),
      thunkAPI.dispatch(fetchTrending(false)),
    ]);

    // get all collections and their meditation or music_track uids
    const spotlightCollections = Selectors.getLocalizedSpotlight(thunkAPI.getState());
    const momentCollections = Selectors.getLocalizedMomentCollections(thunkAPI.getState());
    const newCollections = Selectors.getLocalizedNewCollections(thunkAPI.getState());
    const trendingCollections = Selectors.getLocalizedTrendingCollections(thunkAPI.getState());
    // get the meditations and musicTracks in a map for faster filtering
    const meditationsMap = meditationsSelectors.getMeditationsMap(thunkAPI.getState());
    const musicTracksMap = musicSelectors.getMusicTracksMap(thunkAPI.getState());

    // filter the collections on already fetched items to prevent fetching data that we already have.
    const spotlightMeditationsToFetch =
      spotlightCollections?.filter(
        (collectionItem) =>
          collectionItem.type === CollectionTypes.Meditation &&
          !meditationsMap[(collectionItem as SpotlightMeditationCollection).meditationUid],
      ) ?? [];
    const spotlightMusicTracksToFetch =
      spotlightCollections?.filter(
        (collectionItem) =>
          collectionItem.type === CollectionTypes.MusicTrack &&
          !meditationsMap[(collectionItem as SpotlightMusicTrackCollection).musicTrackUid],
      ) ?? [];
    const collectionMeditationsToFetch = [...momentCollections, ...newCollections, ...trendingCollections].filter(
      ({ type, uid }) => type === CollectionTypes.Meditation && !meditationsMap[uid],
    );
    const collectionMusicTracksToFetch = [...momentCollections, ...newCollections, ...trendingCollections].filter(
      ({ type, uid }) => type === CollectionTypes.MusicTrack && !musicTracksMap[uid],
    );

    // if there are any meditations left to fetch, fetch them.
    if (collectionMeditationsToFetch.length || spotlightMeditationsToFetch.length) {
      const meditationsToFetch = collectionMeditationsToFetch
        .map(({ uid }) => uid)
        .concat(spotlightMeditationsToFetch.map(({ meditationUid }: SpotlightMeditationCollection) => meditationUid));
      const { data } = await Axios.get<{ meditations: Meditation[]; tracks: Track[] }>('/api/meditations', {
        params: { uids: Array.from(new Set(meditationsToFetch)) },
      });

      thunkAPI.dispatch(meditationsActions.updateMeditations(data.meditations));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }

    // if there are any music tracks left to fetch, fetch them.
    if (collectionMusicTracksToFetch.length || spotlightMusicTracksToFetch.length) {
      const musicToFetch = collectionMusicTracksToFetch
        .map(({ uid }) => uid)
        .concat(spotlightMusicTracksToFetch.map(({ musicTrackUid }: SpotlightMusicTrackCollection) => musicTrackUid));
      const { data } = await Axios.get<{ musicTracks: MusicTrack[]; tracks: Track[] }>('/api/music', {
        params: { uids: Array.from(new Set(musicToFetch)) },
      });

      thunkAPI.dispatch(musicActions.updateMusicTracks(data.musicTracks));
      thunkAPI.dispatch(audioTracksActions.updateTracks(data.tracks));
    }
  },
);
