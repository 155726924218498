import { RefObject, useEffect } from 'react';

export default function useClickOutside(ref: RefObject<HTMLElement>, onClickOutside: () => void): void {
  useEffect(() => {
    const click = (event: MouseEvent) => {
      if (!ref.current || ref.current.contains(event.target as HTMLElement)) {
        return;
      }

      onClickOutside();
    };

    document.addEventListener('mousedown', click);
    return () => document.removeEventListener('mousedown', click);
  }, [onClickOutside, ref]);
}
