import styled, { css } from 'styled-components';
import { rgba } from 'polished';
import { colors, typography } from 'assets/styles';

export const StyledButton = styled.button`
  ${typography.button};
  position: relative;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: ${({ $hasIcon }) => `${$hasIcon ? 1.2 : 1.6}rem 2.4rem`};
  background-color: ${({ $isTransparent }) => rgba(colors.tint, $isTransparent ? 0.15 : 1)};
  box-sizing: border-box;

  color: ${({ $isTransparent }) => ($isTransparent ? colors.tint : colors.white)};
  text-decoration: none;
  border: none;
  border-radius: 4.8rem;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:disabled {
    opacity: 0.4;
    cursor: not-allowed;

    @media (any-hover: hover) {
      &:hover {
        opacity: 0.4;
      }
    }
  }

  ${({ $fullWidth }) =>
    $fullWidth &&
    css`
      width: 100%;
    `};

  ${({ $isSmall }) =>
    $isSmall &&
    css`
      padding: 0.8rem 1.6rem;
      line-height: 1.4;
    `};

  ${({ $isWhite, $isTransparent, $isExtraTransparent, $textColor }) => {
    const opacity = $isTransparent ? ($isExtraTransparent ? 0.15 : 0.3) : 1;
    return (
      $isWhite &&
      css`
        color: ${$isTransparent ? colors.white : $textColor || colors.text};
        background-color: ${rgba(colors.white, opacity)};
      `
    );
  }};

  ${({ $hasUnderline }) =>
    $hasUnderline &&
    css`
      padding: 0 0 0.3rem;
      color: ${colors.tint};
      background-color: transparent;
      border-radius: 0;
      border-bottom: 1px solid ${colors.tint};
    `};

  @media (any-hover: hover) {
    &:hover {
      opacity: ${({ $isTransparent }) => !$isTransparent && 0.8};

      ${({ $isTransparent, $isExtraTransparent, $isWhite }) =>
        $isTransparent &&
        css`
          background-color: ${rgba(
            $isWhite ? colors.white : colors.tint,
            $isWhite && !$isExtraTransparent ? 0.1 : 0.05,
          )};
        `};
    }
  }
`;

export const IconWrapper = styled.span`
  display: inline-block;
  line-height: 62.5%;
  margin-right: 0.8rem;
`;
