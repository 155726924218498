import { css } from 'styled-components';
import { breakpointAndUp } from 'assets/styles';
import { DeviceImageSet, Image, ImageSet } from 'models/image';

export function imageToImageSrcProps(image: Image | ImageSet): { src?: string; srcSet?: string } {
  if (!image) return {};

  return typeof (image as Image).src === 'string'
    ? (image as Image)
    : { srcSet: `${image['1x']} 1x, ${image['2x']} 2x, ${image['3x']} 3x` };
}

export function imageToBackgroundImageValues(image: Image | ImageSet): string {
  if (!image) return undefined;

  return typeof (image as Image).src === 'string'
    ? `url('${(image as Image).src}')`
    : `image-set(url('${image['1x']}') 1x, url('${image['2x']}') 2x, url('${image['3x']}') 3x)`;
}

export function imageToBackgroundImageCss(image: Image | ImageSet | DeviceImageSet): string {
  return css`
    background-image: ${imageToBackgroundImageValues((image as DeviceImageSet)?.mobile ?? (image as ImageSet | Image))};

    @media (${breakpointAndUp.tablet}) {
      background-image: ${imageToBackgroundImageValues(
        (image as DeviceImageSet)?.tablet ?? (image as ImageSet | Image),
      )};
    }

    @media (${breakpointAndUp.desktop}) {
      background-image: ${imageToBackgroundImageValues(
        (image as DeviceImageSet)?.desktop ?? (image as ImageSet | Image),
      )};
    }
  `;
}
