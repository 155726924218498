import { css } from 'styled-components';
import { fonts } from './font-families';
import breakpointAndUp from './breakpoints';

const typography = {
  h1: css`
    font-family: ${fonts.heading};
    font-weight: 700;
    font-size: 2.4rem;
    line-height: 2.6rem;

    @media (${breakpointAndUp.tabletLarge}) {
      font-size: 2.8rem;
      line-height: 3.2rem;
    }
  `,
  h2: css`
    font-family: ${fonts.heading};
    font-weight: 700;
    font-size: 2.2rem;
    line-height: 2.6rem;

    @media (${breakpointAndUp.tabletLarge}) {
      font-size: 2.4rem;
      line-height: 2.8rem;
    }
  `,
  h3: css`
    font-family: ${fonts.heading};
    font-weight: 700;
    font-size: 1.8rem;
    line-height: 2.6rem;
  `,
  h4: css`
    font-family: ${fonts.heading};
    font-weight: 700;
    font-size: 1.6rem;
    line-height: 1.2;
  `,
  h5: css`
    font-family: ${fonts.heading};
    font-weight: 600;
    font-size: 1.4rem;
    line-height: 1.4;
  `,
  h6: css`
    font-family: ${fonts.heading};
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.4;
  `,
  caption: css`
    font-family: ${fonts.heading};
    font-weight: 700;
    font-size: 1.2rem;
    line-height: 1.2;
    text-transform: uppercase;
  `,
  captionSmall: css`
    font-family: ${fonts.heading};
    font-weight: 600;
    font-size: 1.2rem;
    line-height: 1.4;
  `,
  button: css`
    font-family: ${fonts.heading};
    font-weight: 700;
    font-size: 1.4rem;
    line-height: 1.2;
  `,
  body: css`
    font-family: ${fonts.primary};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;

    @media (${breakpointAndUp.tabletLarge}) {
      font-size: 1.8rem;
    }
  `,
  bodySmall: css`
    font-family: ${fonts.primary};
    font-weight: 400;
    font-size: 1.6rem;
    line-height: 1.4;
  `,
};

export default typography;
