import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import { AppInfo } from 'models/app-info';
import { useBoundDispatch } from 'domains/redux/utils';
import * as Selectors from './selectors';
import * as Thunks from './thunks';

export function useAppInfo(): [appInfo: AppInfo, loading: boolean] {
  const [loading, setLoading] = useState<boolean>(false);
  const appInfo = useSelector(Selectors.getLocalizedAppInfo);
  const fetchAppInfo = useBoundDispatch(Thunks.fetchAppInfo);

  useEffect(() => {
    if (!Object.keys(appInfo).length) {
      setLoading(true);
      fetchAppInfo(undefined).then(() => setLoading(false));
    }
  }, [appInfo]);

  return [appInfo, loading];
}
