import styled, { css } from 'styled-components';
import { breakpointAndUp, breakpointAndUpShorthands } from 'assets/styles';

const parseContainerColumns = (sizes: string) =>
  sizes?.split(' ').map((col) => {
    const [breakpointShorthand, columns] = col.split(':');
    return css`
      @media (${breakpointAndUpShorthands[breakpointShorthand]}) {
        grid-template-columns: repeat(${columns}, 1fr);
      }
    `;
  });

export const Container = styled.div`
  width: 100%;
  max-width: ${({ $fullWidth }) => ($fullWidth ? 'auto' : '126rem')};
  margin: 0 auto;
`;

export const Row = styled.div`
  ${({ $columns }) => parseContainerColumns($columns)};
  display: grid;
  grid-gap: 1.2rem;
  margin: 0 2.4rem;

  @media (${breakpointAndUp.tabletLarge}) {
    grid-gap: 1.6rem;
    margin: 0 3.2rem;
  }

  @media (${breakpointAndUp.desktop}) {
    margin: 0 4.8rem;
  }
`;

const parseColumnSizes = (sizes: string, offset?: boolean) =>
  sizes?.split(' ').map((col) => {
    const [breakpointShorthand, columns] = col.split(':');
    return css`
      @media (${breakpointAndUpShorthands[breakpointShorthand]}) {
        ${offset
          ? css`
              grid-column-start: ${columns}; //todo: improve the offset when needed.
            `
          : css`
              grid-column: span ${columns};
            `};
      }
    `;
  });

export const Col = styled.div`
  ${({ $size }) => parseColumnSizes($size)};
  ${({ $offset }) => parseColumnSizes($offset, true)};
  order: ${({ $order }) => $order};
  width: 100%;
`;
