export const breakpoints = {
  mobile: '0px',
  tablet: '600px',
  tabletLarge: '960px',
  desktop: '1280px',
};

const breakpointAndUp = {
  mobile: `min-width: ${breakpoints.mobile}`,
  tablet: `min-width: ${breakpoints.tablet}`,
  tabletLarge: `min-width: ${breakpoints.tabletLarge}`,
  desktop: `min-width: ${breakpoints.desktop}`,
};

export enum BreakpointShorthands {
  Mobile = 'mb',
  Tablet = 'tb',
  TabletLarge = 'tl',
  Desktop = 'dt',
}

export const breakpointAndUpShorthands = {
  [BreakpointShorthands.Mobile]: breakpointAndUp.mobile,
  [BreakpointShorthands.Tablet]: breakpointAndUp.tablet,
  [BreakpointShorthands.TabletLarge]: breakpointAndUp.tabletLarge,
  [BreakpointShorthands.Desktop]: breakpointAndUp.desktop,
};

export default breakpointAndUp;
